import React from "react";

const LookAtOurPrices = () => {
  return (
    <section className="look-at-our-prices">
      <h2 className="look-at-our-prices-title">
        Pogledajte naše usluge i cijene
      </h2>

      <div className="look-at-our-prices-table">
        <div className="look-at-our-prices-table-header">
          <div className="look-at-our-prices-table-header-item">Usluga</div>
          <div className="look-at-our-prices-table-header-item">
            Kratki opis usluge
          </div>
          <div className="look-at-our-prices-table-header-item">Cijena</div>
        </div>{" "}
        <div className="look-at-our-prices-table-row">
          <div className="look-at-our-prices-table-row-item">
            TRANSPORT ROBE
          </div>
          <div className="look-at-our-prices-table-row-item">
            Obavljamo transport robe za poslovne subjekte.
          </div>
          <div className="look-at-our-prices-table-row-item">
            Cijena po dogovoru.{" "}
          </div>
        </div>{" "}
        <div className="look-at-our-prices-table-row">
          <div className="look-at-our-prices-table-row-item">Selidba</div>
          <div className="look-at-our-prices-table-row-item">
            Selidba vašeg doma
          </div>
          <div className="look-at-our-prices-table-row-item">
            Cijena po dogovoru.
          </div>
        </div>{" "}
        <div className="look-at-our-prices-table-row">
          <div className="look-at-our-prices-table-row-item">
            Selidba poslovnog prostora
          </div>
          <div className="look-at-our-prices-table-row-item">
            Osiguravamo sigurno preseljenje vašeg ureda
          </div>
          <div className="look-at-our-prices-table-row-item">
            Cijena po dogovoru.
          </div>
        </div>
        <div className="look-at-our-prices-table-row">
          <div className="look-at-our-prices-table-row-item">
            DOSTAVA PAKETA
          </div>
          <div className="look-at-our-prices-table-row-item">
            Osiguravamo brzu dostavu paketa na željenu adresu.
          </div>
          <div className="look-at-our-prices-table-row-item">
            Cijena po dogovoru.
          </div>
        </div>{" "}
        <div className="look-at-our-prices-table-row">
          <div className="look-at-our-prices-table-row-item">
            ODVOZ GLOMAZNOG OTPADA
          </div>
          <div className="look-at-our-prices-table-row-item">
            Pomažemo vam kod odvoza glomaznog otpada.
          </div>
          <div className="look-at-our-prices-table-row-item">
            Cijena po dogovoru.
          </div>
        </div>{" "}
        <div className="look-at-our-prices-table-row">
          <div className="look-at-our-prices-table-row-item">
            DOSTAVA ROBE IZ TRGOVAČKIH CENTARA
          </div>
          <div className="look-at-our-prices-table-row-item">
            Obavljamo dostavu robe iz trgovačkih centara.
          </div>
          <div className="look-at-our-prices-table-row-item">
            Cijena po dogovoru.
          </div>
        </div>{" "}
      </div>
    </section>
  );
};

export default LookAtOurPrices;
