import React, { useEffect } from "react";
import "../styles/Page.scss";
import LookAtOurPrices from "../components/LookAtOurPrices";

const Prices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className="page prices-page">
      <LookAtOurPrices />
    </main>
  );
};

export default Prices;
