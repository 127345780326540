import React, { useState } from "react";
import van from "../assets/images/van.webp";

const Questions = () => {
  const [questions, setQuestions] = useState([
    {
      active: false,
      question: "KolikE SU CIJENE USLUGA?",
      answer:
        "Cijena usluge  ovisi o više faktora. Na cijenu utječu udaljenost između polazišta i odredišta i količina robe koja se prevozi. ",
    },
    {
      active: false,
      question: "Kako se može naručiti usluga?",
      answer:
        "Uslugu možete naručiti putem telefona: +385 92 381 3331, mail-a: gabi.klaric18@gmail.com ili putem naše web stranice.",
    },
    {
      active: false,
      question: "Način plaćanja?",
      answer:
        "Usluge možete platiti gotovinom ili preko transakcijskog računa.",
    },
  ]);

  return (
    <section className="questions">
      <h2 className="questions-title">Često postavljana pitanja</h2>
      <p className="questions-description">
        Ogovorili smo na neka od najčešćih pitanja koja nam postavljaju naši
        klijenti. Ako imate dodatnih pitanja, slobodno nas kontaktirajte.
      </p>
      <div className="questions-content">
        {questions.map((question, index) => {
          return (
            <div className="question" key={index}>
              <button
                className="question-button"
                onClick={() => {
                  const newQuestions = questions.map((q, qIndex) => {
                    if (index === qIndex) {
                      q.active = !q.active;
                    }
                    return q;
                  });
                  setQuestions(newQuestions);
                }}
              >
                <h3 className="question-title">
                  {index + ". " + question.question}
                </h3>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                  style={{
                    transform: question.active
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m19.5 8.25-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </button>
              <p
                className={
                  question.active ? "question-answer active" : "question-answer"
                }
              >
                {question.answer}
              </p>
            </div>
          );
        })}
      </div>

      <div className="background-image">
        <img src={van} alt="van" />
      </div>
    </section>
  );
};

export default Questions;
