import React, { useState, useEffect } from "react";
import logo from "../assets/images/logo.svg";
import facebook from "../assets/icons/facebook.svg";
import instagram from "../assets/icons/instagram.svg";
import whatsUpp from "../assets/icons/whatsupp.svg";
import { Link, NavLink, useLocation } from "react-router-dom";
import "../styles/Navbar.scss";

const Navbar = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setShowMobileMenu(false); // Set showMobileMenu to false when the URL changes
  }, [location.pathname]);

  return (
    <header className="header">
      <div className="top-menu-bar">
        <div className="top-menu-bar-left">
          <a href="tel:+385923813331">Tel: +385 92 381 3331</a>
          <a href="mailto:gabi.klaric18@gmail.com">
            E-mail: gabi.klaric18@gmail.com
          </a>
        </div>
        <div className="top-menu-bar-center">
          <p> PRIJEVOZ - SELIDBE </p>
        </div>

        <div className="top-menu-bar-right socials">
          <a
            href="https://www.facebook.com/klaricprijevoz"
            target="_blank"
            rel="noreferrer"
          >
            <img src={facebook} alt="facebook" />
          </a>
          <a
            href="https://www.instagram.com/klaric_prijevoz/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instagram} alt="instagram" />
          </a>
          <a href="tel:+385923813331" target="_blank" rel="noreferrer">
            <img src={whatsUpp} alt="whatsupp" />
          </a>
        </div>
      </div>
      <nav className="nav-bar">
        <ul className="main-menu">
          <li>
            <NavLink className="nav-link" to="/">
              Početna
            </NavLink>
          </li>
          <li>
            <NavLink className="nav-link" to="/o-nama">
              O nama
            </NavLink>
          </li>
          <li>
            <NavLink className="nav-link" to="/cjenik">
              Cjenik
            </NavLink>
          </li>
        </ul>
        <Link className="logo" to="/">
          <img src={logo} alt="logo" />
        </Link>
        <Link className="button" to="/kontakt">
          <div className="triangle-left"></div>
          <span>Kontakt</span>
          <div className="triangle-right"></div>
        </Link>

        <button className="menu-button" onClick={() => setShowMobileMenu(true)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </button>
      </nav>
      <nav className={`mobile__menu ${showMobileMenu ? "show" : ""}`}>
        <div className="top">
          <Link className="logo" to="/">
            <img src={logo} alt="logo" />
          </Link>{" "}
        </div>

        <div className="links">
          <NavLink to="/" exact>
            Početna
          </NavLink>{" "}
          <NavLink to="/o-nama">O nama</NavLink>
          <NavLink to="/cjenik">Cjenik</NavLink>
          <NavLink to="/kontakt">Kontaktirajte nas</NavLink>
        </div>

        <div className="bottom">
          <div className="socials">
            <a
              href="https://www.facebook.com/klaricprijevoz"
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebook} alt="facebook" />
            </a>
            <a
              href="https://www.instagram.com/klaric_prijevoz/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={instagram} alt="instagram" />
            </a>
            <a href="tel:+385923813331" target="_blank" rel="noreferrer">
              <img src={whatsUpp} alt="whatsupp" />
            </a>
          </div>
          <div className="rights">
            <p>© 2024 Klarić Prijevoz. Sva prava pridržana.</p>
          </div>
        </div>

        <button
          className={showMobileMenu ? "close__btn show" : "close__btn"}
          onClick={() => setShowMobileMenu(false)}
        ></button>
      </nav>
    </header>
  );
};

export default Navbar;
