import React from "react";
import facebook from "../assets/icons/facebook.svg";
import instagram from "../assets/icons/instagram.svg";
import whatsUpp from "../assets/icons/whatsupp.svg";
import "../styles/Footer.scss";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-sections">
        <section className="footer-section">
          <h3 className="footer-section-title">O NAMA</h3>
          <p className="footer-section-description">
            Klarić Prijevoz je tvrtka koja se bavi prijevozom robe i ljudi. Naša
            tvrtka nudi različite usluge transporta.
          </p>
          <p>Ponedjeljak - Petak: 8:00 - 16:00</p>
          <p>Subota: 8:00 - 12:00</p>
          <p>Nedjelja: Zatvoreno</p>
        </section>

        <section className="footer-section">
          <h3 className="footer-section-title"> Kontakt informacije</h3>
          <h4 className="footer-section-subtitle">Telefon</h4>
          <a href="tel:+385923813331" target="_blank" rel="noreferrer">
            +385 92 381 3331
          </a>
          <h4 className="footer-section-subtitle">E-mail</h4>
          <a
            href="mailto:gabi.klaric18@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            gabi.klaric18@gmail.com
          </a>
        </section>

        <section className="footer-section">
          <h3 className="footer-section-title">LOKACIJA</h3>
          <a
            href="https://maps.app.goo.gl/gRfRptPsbzdeJo6y6"
            target="_blank"
            rel="noreferrer"
          >
            Zagreb
            <br />
            10 090
            <br />
            Sigetje 12
          </a>
        </section>

        <nav className="footer-section nav">
          <h3> MENI</h3>
          <Link to="/" className="footer-section-link">
            Početna
          </Link>
          <Link to="/o-nama" className="footer-section-link">
            O nama
          </Link>
          <Link to="/cjenik" className="footer-section-link">
            Cjenik
          </Link>
          <Link to="/kontakt" className="footer-section-link">
            Kontakt
          </Link>
        </nav>
      </div>

      <div className="footer-socials-and-legal">
        <nav className=" footer-legal">
          <Link to="/pravila-privatnosti">Pravila privatnosti</Link>
          <Link href="/uvjeti-korištenja">Uvjeti korištenja</Link>
        </nav>
        <section className="footer-socials">
          <a
            href="https://www.facebook.com/klaricprijevoz"
            target="_blank"
            rel="noreferrer"
          >
            <img src={facebook} alt="facebook" />
          </a>
          <a
            href="https://www.instagram.com/klaric_prijevoz/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instagram} alt="instagram" />
          </a>
          <a href="tel:+385923813331" target="_blank" rel="noreferrer">
            <img src={whatsUpp} alt="whatsupp" />
          </a>
        </section>
      </div>
      <section className="footer-rights">
        <p>&copy; 2024 Klarić Prijevoz. Sva prava pridržana.</p>
      </section>

      <section className="footer-creator">
        <p>
          Web stranicu izradio: &nbsp;
          <a href="https://msk.hr/" target="_blank" rel="noreferrer">
            msk.hr
          </a>
        </p>
      </section>
    </footer>
  );
};

export default Footer;
