import React from "react";
import googleReviews from "../assets/images/google-reviews.png";
import userIcon from "../assets/images/user.png";
import van from "../assets/images/van.webp";
import star from "../assets/icons/star.svg";

const Reviews = () => {
  const reviews = [
    {
      name: "Ivan Horvat",
      review:
        "Oduševljen sam uslugom ove tvrtke za kombi prijevoz! Cijeli proces selidbe iz našeg stambenog objekta protekao je glatko i bez ikakvih problema. Vozači su bili iznimno ljubazni i pažljivi sa svim našim stvarima, što je cijelo iskustvo učinilo manje stresnim.",
      rating: 5,
      dateAndTime: "3. Prosinca 2023  ",
    },
    {
      name: "Luka Kordić",
      review:
        "Zahvaljujući ovoj tvrtki, moja selidba je prošla brzo - svakako ću ih ponovno koristiti!",
      rating: 5,
      dateAndTime: "4. Siječnja 2024  ",
    },
    {
      name: "Josip Brkanović",
      review:
        "Izvanredna usluga po povoljnim cijenama - moja selidba nikada nije bila lakša!",
      rating: 5,
      dateAndTime: "24. Veljače 2024  ",
    },
    {
      name: "Sanja Kovač",
      review:
        "Preporučujem ovu tvrtku svima koji traže pouzdanu i efikasnu uslugu kombi prijevoza. Komunikacija s njima bila je jednostavna i brza, a njihovi zaposlenici su pokazali visoku razinu profesionalnosti tijekom cijele selidbe. Zadovoljan sam brzinom i pažnjom kojom su obavili posao",
      rating: 5,
      dateAndTime: "17. Prosinca 2023  ",
    },
  ];

  return (
    <section className="reviews">
      <div className="reviews-google-reviews-logo">
        <img src={googleReviews} alt="Google Reviews Logo" />
      </div>
      <h2 className="reviews-title">Što naši klijenti kažu o nama</h2>
      <p className="reviews-description">
        Pogledajte recenzije naših klijenata i uvjerite se sami u kvalitetu
        naših usluga.
      </p>
      <div className="reviews-content">
        <div className="reviews-content-col big">
          <div className="triangle-left"></div>
          <div className="triangle-right"></div>
          <div className="review">
            <div className="review-user">
              <img src={userIcon} alt={reviews[0].name} />
              <h3 className="review-user-name">{reviews[0].name}</h3>
            </div>
            <p className="review-text">{reviews[0].review}</p>{" "}
            <div className="review-rating">
              <img src={star} alt="first-star-1" className="star" />
              <img src={star} alt="first-star-2" className="star" />
              <img src={star} alt="first-star-3" className="star" />
              <img src={star} alt="first-star-4" className="star" />
              <img src={star} alt="first-star-5" className="star" />
            </div>
            <p className="review-date-and-time">{reviews[0].dateAndTime}</p>
          </div>
        </div>
        <div className="reviews-content-col">
          <div className="triangle-left"></div>
          <div className="triangle-right"></div>
          <div className="review">
            <div className="review-user">
              <img src={userIcon} alt={reviews[1].name} />
              <h3 className="review-user-name">{reviews[1].name}</h3>
            </div>
            <p className="review-text">{reviews[1].review}</p>
            <div className="review-rating">
              <img src={star} alt="second-star-1" className="star" />
              <img src={star} alt="second-star-2" className="star" />
              <img src={star} alt="second-star-3" className="star" />
              <img src={star} alt="second-star-4" className="star" />
              <img src={star} alt="second-star-5" className="star" />
            </div>
            <p className="review-date-and-time">{reviews[1].dateAndTime}</p>
          </div>
          <div className="review">
            <div className="review-user">
              <img src={userIcon} alt={reviews[2].name} />
              <h3 className="review-user-name">{reviews[2].name}</h3>
            </div>{" "}
            <p className="review-text">{reviews[2].review}</p>{" "}
            <div className="review-rating">
              <img src={star} alt="third-star-1" className="star" />
              <img src={star} alt="third-star-2" className="star" />
              <img src={star} alt="third-star-3" className="star" />
              <img src={star} alt="third-star-4" className="star" />
              <img src={star} alt="third-star-5" className="star" />
            </div>
            <p className="review-date-and-time">{reviews[2].dateAndTime}</p>
          </div>
        </div>
        <div className="reviews-content-col big">
          <div className="triangle-left"></div>
          <div className="triangle-right"></div>
          <div className="review">
            <div className="review-user">
              <img src={userIcon} alt={reviews[3].name} />
              <h3 className="review-user-name">{reviews[3].name}</h3>
            </div>
            <p className="review-text">{reviews[3].review}</p>{" "}
            <div className="review-rating">
              <img src={star} alt="fourth-star-1" className="star" />
              <img src={star} alt="fourth-star-2" className="star" />
              <img src={star} alt="fourth-star-3" className="star" />
              <img src={star} alt="fourth-star-4" className="star" />
              <img src={star} alt="fourth-star-5" className="star" />
            </div>
            <p className="review-date-and-time">{reviews[3].dateAndTime}</p>
          </div>
        </div>
      </div>
      <div className="background-image">
        <img src={van} alt="van-12312" />
      </div>
    </section>
  );
};

export default Reviews;
