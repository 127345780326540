import React, { useEffect } from "react";
import "../styles/Page.scss";
import SendUsAMessage from "../components/SendUsAMessage";
import MapPoint from "../components/MapPoint";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className="page contact-page">
      <MapPoint />
      <SendUsAMessage />
    </main>
  );
};

export default Contact;
