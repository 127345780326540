import React from "react";
import vanReflect from "../assets/images/van-reflect.webp";
import safety from "../assets/icons/safety.svg";
import time from "../assets/icons/time.svg";
import user from "../assets/icons/user.svg";
import review from "../assets/icons/review.svg";

const WhyChooseUs = () => {
  return (
    <section className="why-choose-us">
      <h2 className="why-choose-us-title">Zašto odabrati nas?</h2>
      <p className="why-choose-us-description">
        Siguran i pouzdan partner.
      </p>{" "}
      <div className="background-image">
        <img src={vanReflect} alt="transparent-van" />
      </div>
      <div className="why-choose-us-content">
        <div className="why-choose-us-content-item">
          <div className="why-choose-us-content-icon">
            <img src={safety} alt="Safety" />
          </div>
          <h3 className="why-choose-us-content-title">Sigurnost</h3>
          <p className="why-choose-us-content-description">
            Siguran prijevoz robe.
          </p>
        </div>
        <div className="why-choose-us-content-item">
          <div className="why-choose-us-content-icon">
            <img src={time} alt="Time" />
          </div>
          <h3 className="why-choose-us-content-title">uvijek na vrijeme</h3>
          <p className="why-choose-us-content-description">
            Efikasno izvršavanje usluga prema dogovoru i u dogovorenim rokovima.
          </p>{" "}
        </div>
        <div className="why-choose-us-content-item">
          <div className="why-choose-us-content-icon">
            <img src={user} alt="employers" />
          </div>
          <h3 className="why-choose-us-content-title">Iskusni vozači</h3>
          <p className="why-choose-us-content-description">
            Kvalificirani i iskusni vozači kojima je Vaša roba na prvom mjestu.
          </p>
        </div>
        <div className="why-choose-us-content-item">
          <div className="why-choose-us-content-icon">
            <img src={review} alt="review" />
          </div>
          <h3 className="why-choose-us-content-title">Odlične recenzije</h3>
          <p className="why-choose-us-content-description">
            Pogledajte recenzije naših klijenata i uvjerite se sami u kvalitetu
            naših usluga.
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
