import React from "react";
import hero from "../assets/images/hero.jpg";
import ContactForm from "./ContactForm";

const Hero = () => {
  return (
    <section className="hero">
      <div className="hero-content">
        <div className="hero-image">
          <img src={hero} alt="Kombi prijevoz" />
        </div>
        <div className="hero-subtitle">
          <div className="triangle-left"></div>
          <span>PRIJEVOZ PO CIJELOJ HRVATSKOJ</span>
          <div className="triangle-right"></div>
        </div>
        <h1 className="hero-title">KOMBI PRIJEVOZ</h1>
        <p className="hero-description">
          Kombi prijevoz nudi fleksibilne i pouzdane usluge transporta za
          poslovne potrebe. S konkurentnim cijenama i prilagođenim rješenjima,
          naša usluga osigurava da vaša roba sigurno i učinkovito stigne na
          odredište. Bez obzira na veličinu ili težinu pošiljke, posvećeni smo
          pružanju izvrsne usluge uz poštivanje zadanih rokova.
        </p>
      </div>
      <ContactForm />
    </section>
  );
};

export default Hero;
