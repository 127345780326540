import React, { useEffect } from "react";
import Hero from "../components/Hero";
import AboutUsComponent from "../components/AboutUsComponent";
import "../styles/Page.scss";
import WhatWeDo from "../components/WhatWeDo";
import MapPoint from "../components/MapPoint";
import Reviews from "../components/Reviews";
import WhyChooseUs from "../components/WhyChooseUs";
import LookAtOurPrices from "../components/LookAtOurPrices";
import Questions from "../components/Questions";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className="page home-page">
      <Hero />
      <AboutUsComponent />
      <WhatWeDo />
      <MapPoint />
      <Reviews />
      <WhyChooseUs />
      <LookAtOurPrices />
      <Questions />
    </main>
  );
};

export default Home;
