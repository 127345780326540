import React from "react";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
} from "@vis.gl/react-google-maps";

const MapPoint = () => {
  const apiKey = "AIzaSyDe07jdWY3XT3XlacF0OUOJyKY0SbaHt4I";

  // 45.81553904725637, 15.874125081653064
  const position = {
    lat: 45.81553904725637,
    lng: 15.874125081653064,
  };

  return (
    <section className="map">
      <section className="contact-information">
        <h2>KONTAKT INFORMACIJE</h2>
        <p>
          Ukoliko ste zainteresirani za naše usluge možete nas kontaktirati na
          broj telefona ili putem e-maila koji su napisani ispod. Na ovoj
          stranici možete pronaći i našu lokaciju na karti.
        </p>
        <div className="contact-info">
          <div className="contact-info-address">
            <h3>Adresa</h3>
            <a
              href="https://maps.app.goo.gl/7suyNyRWSDBrYaz66"
              target="_blank"
              rel="noreferrer"
            >
              Zagreb
              <br />
              10 090
              <br />
              Sigetje 12
            </a>
          </div>
          <div className="contact-info-right">
            <div className="contact-info-phone">
              <h3>Telefon</h3>
              <a href="tel:+385923813331" target="_blank" rel="noreferrer">
                +385 92 381 3331
              </a>
            </div>
            <div className="contact-info-email">
              <h3>E-mail</h3>

              <a
                href="mailto:gabi.klaric18@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                gabi.klaric18@gmail.com
              </a>
            </div>
          </div>
        </div>

        <button
          className="button"
          onClick={() =>
            window.open("https://maps.app.goo.gl/7suyNyRWSDBrYaz66")
          }
        >
          <div className="triangle-left"></div>
          <span>Dobij upute</span>
          <div className="triangle-right"></div>
        </button>
      </section>
      <APIProvider apiKey={apiKey}>
        <section className="map-container">
          <Map center={position} mapId={"264befe9355da411"} zoom={17}>
            <AdvancedMarker
              position={position}
              onClick={(e) => {
                window.open("https://maps.app.goo.gl/7suyNyRWSDBrYaz66");
              }}
            >
              <Pin />
            </AdvancedMarker>
          </Map>
        </section>
      </APIProvider>
    </section>
  );
};

export default MapPoint;
