import React, { useState } from "react";
import "../styles/Cookies.scss";

const Cookies = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  const handleCookiesAccept = () => {
    setCookiesAccepted(true);
  };

  return (
    <>
      <section className={`cookies ${cookiesAccepted ? "cookies-hidden" : ""}`}>
        <p className="cookies-description">
          Koristimo kolačiće kako bismo vam pružili najbolje iskustvo na našoj
          web stranici. Informacije o kolačićima koje koristimo ili opcije za
          isključivanje kolačića možete pronaći u{" "}
          <span onClick={() => console.log("Cookies settings")}>
            postavkama kolačića
          </span>
          .
        </p>
        <div className="cookies-buttons">
          <button
            className="cookies-button cookies-accept"
            onClick={handleCookiesAccept}
          >
            <span>Prihvaćam</span>🍪
          </button>
          <button className="cookies-button cookies-settings">
            <span>Postavke</span>
          </button>
        </div>
      </section>
    </>
  );
};

export default Cookies;
