import React, { useState } from "react";
import furnitureIcon from "../assets/icons/furniture-icon.svg";
import movingOutIcon from "../assets/icons/moving-out-icon.svg";
import deliveryPackageIcon from "../assets/icons/delivery-packages-icon.svg";
import fastDeliveryIcon from "../assets/icons/fast-delivery.svg";
import fastDeliveryImage from "../assets/images/fast-delivery-image.png";
import deliveryPackageImage from "../assets/images/package-delivery-image.png";
import movingOutImage from "../assets/images/moving-out-image.png";
import shoppingMallDeliveryIcon from "../assets/icons/shopping-icon.svg";
import mallImage from "../assets/images/mall-image.png";
import furnitureImage from "../assets/images/furniture-image.png";

const WhatWeDo = () => {
  const jobs = [
    {
      icon: movingOutIcon,
      title: "Selidba Vašeg doma",
      description:
        "Pomažemo vam kod selidbe vašeg doma. Naša usluga uključuje pakiranje, transport i istovar vaših stvari.",
      image: movingOutImage,
    },

    {
      icon: deliveryPackageIcon,
      title: "Prijevoz robe za poslovne subjekte",
      description:
        "Obavljamo transport robe za poslovne subjekte. Sigurno i brzo dostavljamo vašu robu na željenu adresu.",
      image: deliveryPackageImage,
    },
    {
      icon: fastDeliveryIcon,
      title: "Brza dostava",
      description:
        "Brza dostava vaših stvari. Naša usluga uključuje brzu dostavu vaših stvari na željenu adresu.",
      image: fastDeliveryImage,
    },
    {
      icon: furnitureIcon,
      title: "Odvoz glomaznog otpada",
      description:
        "Pomažemo vam kod odvoza glomaznog otpada. Naša usluga uključuje odvoz glomaznog otpada na siguran način.",
      image: furnitureImage,
    },
    {
      icon: shoppingMallDeliveryIcon,
      title: "Dostava robe iz trgovačkih centara",
      description:
        "Obavljamo dostavu robe iz trgovačkih centara. Sigurno i brzo dostavljamo vašu robu na željenu adresu.",
      image: mallImage,
    },
  ];
  const [selectedJob, setSelectedJob] = useState(jobs[0]);
  return (
    <section className="what-we-do">
      <img
        className="background-image"
        src={selectedJob.image}
        alt={selectedJob.title + "- image"}
      />
      <div className="what-we-do-title-and-description">
        <h2 className="what-we-do-title">Pogledajte čime se sve bavimo?</h2>
        <p className="what-we-do-description">
          Naša tvrtka nudi različite usluge transporta. Usluga uključuje selidbu
          vašeg doma, dostavu paketa i brzu dostavu vaših stvari. Pogledajte
          naše usluge i odaberite onu koja vam najviše odgovara. Ističemo uslugu
          prijevoza robe za poslovne subjekte.
        </p>
      </div>
      <div className="what-we-do-jobs">
        {jobs.map((job, index) => {
          return (
            <div
              className={
                selectedJob === job
                  ? "what-we-do-job selected"
                  : "what-we-do-job"
              }
              key={index}
              onMouseEnter={() => setSelectedJob(job)}
            >
              <img
                className="what-we-do-job-icon"
                src={job.icon}
                alt={job.title + "- icon"}
              />
              <h3 className="what-we-do-job-title">{job.title}</h3>
              <p className="what-we-do-job-description">{job.description}</p>
              <div className="what-we-do-job-background-image">
                <img src={job.image} alt={job.title + "- image"} />
              </div>
            </div>
          );
        })}
      </div>{" "}
    </section>
  );
};

export default WhatWeDo;
