import React from "react";
import Form from "./Form";

const SendUsAMessage = () => {
  return (
    <section className="send-us-a-message">
      <h2 className="send-us-a-message-title">Pošaljite nam poruku</h2>
      <p className="send-us-a-message-description">
        Ako imate bilo kakvih pitanja, slobodno nas kontaktirajte. Naš tim
        stručnjaka je tu da vam pomogne.
      </p>
      <Form />
    </section>
  );
};

export default SendUsAMessage;
