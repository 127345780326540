import React from "react";
import aboutUs from "../assets/images/van.webp";

const AboutUsComponent = () => {
  return (
    <section className="about-us-component">
      <div className="about-us-content">
        <h1 className="about-us-title">O NAMA</h1>
        <p className="about-us-description">
          Kombi prijevoz nudi fleksibilne i pouzdane usluge transporta za
          poslovne potrebe. S konkurentnim cijenama i prilagođenim rješenjima,
          naša usluga osigurava da vaša roba sigurno i učinkovito stigne na
          odredište. Bez obzira na veličinu ili težinu pošiljke, posvećeni smo
          pružanju izvrsne usluge uz poštivanje zadanih rokova.
        </p>

        <div className="about-us-achievements">
          <div className="about-us-achievement">
            <h3 className="about-us-achievement-title">PRIJEĐENI KILOMETRI</h3>
            <h2 className="about-us-achievement-text">200 000+ km</h2>
          </div>
          <div className="about-us-achievement">
            <h3 className="about-us-achievement-title">ZADOVOLJNI KLIJENTI</h3>
            <h2 className="about-us-achievement-text">100+</h2>
          </div>
        </div>
      </div>
      <div className="about-us-image">
        <img src={aboutUs} alt="Kombi prijevoz" />
      </div>
    </section>
  );
};

export default AboutUsComponent;
