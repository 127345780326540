import React, { useEffect } from "react";
import "../styles/Page.scss";
import AboutUsComponent from "../components/AboutUsComponent";
import WhatWeDo from "../components/WhatWeDo";
import Questions from "../components/Questions";
import WhyChooseUs from "../components/WhyChooseUs";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="page about-us-page">
      <AboutUsComponent />
      <WhatWeDo />
      <WhyChooseUs />
      <Questions />
    </main>
  );
};

export default AboutUs;
