import React from "react";

const Form = () => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    // change the date fro mm/dd/yyyy to dd/mm/yyyy
    const date = formData.get("date");
    const newDate = date.split("-").reverse().join(".") + ".";

    const data = {
      name: formData.get("name"),
      surname: formData.get("surname"),
      email: formData.get("email"),
      phone: formData.get("phone"),
      date: newDate,
      time: formData.get("time"),
      message: formData.get("message"),
    };

    console.log(data);

    try {
      const response = await fetch(
        "https://mail-server-gold.vercel.app/send-email/klaric-prijevoz",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      await response.json();

      alert("Poruka je uspješno poslana!");
    } catch (error) {
      console.error("Error:", error);
      alert("Došlo je do greške prilikom slanja poruke!");
    }
  };

  return (
    <form className="contact-form" onSubmit={handleSubmit} action="">
      <div className="contact-form-row">
        <div className="contact-form-group">
          <label htmlFor="name">Ime</label>
          <input type="text" id="name" name="name" required placeholder="Ime" />
        </div>
        <div className="contact-form-group">
          <label htmlFor="surname">Prezime</label>
          <input
            type="text"
            id="surname"
            name="surname"
            required
            placeholder="Prezime"
          />
        </div>
        <div className="contact-form-group">
          <label htmlFor="email">E-mail</label>
          <input
            type="email"
            id="email"
            name="email"
            required
            placeholder="E-mail"
          />
        </div>
      </div>
      <div className="contact-form-row">
        <div className="contact-form-group">
          <label htmlFor="phone">Telefon</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            required
            placeholder="Telefon"
          />
        </div>
        <div className="contact-form-group">
          <label htmlFor="date">Datum</label>
          <input type="date" id="date" name="date" required />
        </div>
        <div className="contact-form-group">
          <label htmlFor="time">Vrijeme</label>
          <input type="time" id="time" name="time" required />
        </div>
      </div>
      <div className="contact-form-group">
        <label htmlFor="message">Poruka</label>
        <textarea
          id="message"
          placeholder="Poštovani, zanima me..."
          name="message"
          required
        ></textarea>
      </div>
      <div className="contact-form-row">
        <div className="contact-form-checkbox-group">
          <input type="checkbox" id="gdpr" name="gdpr" required />
          <label htmlFor="gdpr">
            Slažem se s uvjetima korištenja i politikom privatnosti
          </label>
        </div>
        <button className="button" type="submit">
          <div className="triangle-left"></div>
          <span>Pošalji</span>
          <div className="triangle-right"></div>
        </button>
      </div>
    </form>
  );
};

export default Form;
