import React from "react";
import "../styles/ContactForm.scss";
import instagramWhite from "../assets/icons/instagram-white.svg";
import facebookWhite from "../assets/icons/facebook-white.svg";
import whatsUppWhite from "../assets/icons/whatsupp-white.svg";
import Form from "./Form.js";

const ContactForm = () => {
  return (
    <section className="contact-section">
      <section className="contact-information">
        <h2>KONTAKT INFORMACIJE</h2>
        <p>
          Ukoliko ste zainteresirani za naše usluge možete nas kontaktirati
          ispunjavanjem forme desno ili direktno mailom odnosno pozivom na broj
          telefona koji su napisani ispod.
        </p>
        <div className="contact-info">
          <div className="contact-info-address">
            <h3>Adresa</h3>
            <a
              href="https://maps.app.goo.gl/gRfRptPsbzdeJo6y6"
              target="_blank"
              rel="noreferrer"
            >
              Zagreb
              <br />
              10 090
              <br />
              Sigetje 12
            </a>
          </div>
          <div className="contact-info-right">
            <div className="contact-info-phone">
              <h3>Telefon</h3>
              <a href="tel:+385923813331" target="_blank" rel="noreferrer">
                +385 92 381 3331
              </a>
            </div>
            <div className="contact-info-email">
              <h3>E-mail</h3>

              <a
                href="mailto:gabi.klaric18@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                gabi.klaric18@gmail.com{" "}
              </a>
            </div>
          </div>
        </div>

        <div className="contact-socials">
          <p className="contact-socials-title">
            Pratite nas na društvenim mrežama:
          </p>
          <div className="contact-socials-icons">
            <a
              href="https://www.facebook.com/klaricprijevoz"
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebookWhite} alt="facebook" />
            </a>
            <a
              href="https://www.instagram.com/klaric_prijevoz/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={instagramWhite} alt="instagram" />
            </a>
            <a href="tel:+38592381 3331" target="_blank" rel="noreferrer">
              <img src={whatsUppWhite} alt="whatsupp" />
            </a>
          </div>
        </div>
      </section>
      <Form />
    </section>
  );
};

export default ContactForm;
